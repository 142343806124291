.phone-emulator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .phone {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 0px #d3d3d3;
    &.iphone-6 {
      height: 667px;
      width: 375px;
    }

    &.iphone-10 {
      height: 812px;
      width: 375px;
    }

    &.iphone-12-pro-max {
      height: 926px;
      width: 428px;
    }

    .phone-logo {
      width: 100%;
      flex: 4;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .ending-in {
      padding-top: 10px;
      padding-bottom: 20px;
      text-align: center;
    }

    .phone-banner {
      flex: 7;
      width: 100%;
      min-height: 300px;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .start-date {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }

      .pts-per-mo {
        height: 90px;
        width: 90px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
      }
    }

    .phone-data {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      .title {
        .title-name {
          font-size: 30px;
        }
      }

      .large {
        font-size: 50px;
      }
    }
  }
}
.color-row {
  margin: 10px 0;
  display: flex;
  width: 100%;

  .color-block {
    width: 50px;
    height: 50px;
    margin: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}

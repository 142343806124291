.expand-container {
  .MuiAccordionSummary-expandIconWrapper {
    color: white !important;
    margin-right: 15px;
  }
  .MuiAccordionDetails-root {
    padding: 0;
    padding-left: 20px;
  }
  .MuiAccordionSummary-root .mui-expanded {
    height: 75px;
    min-height: 48px;
  }
}
